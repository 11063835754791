@keyframes example {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.width-90 {
  width: 66.66666666667%;
  max-width: none !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="width-"] {
    width: 96%;
  }
}

.nav-link {
  transition: all 250ms;
}

.nav-link:hover {
  transform: translateY(3px);
}

.my-btn {
  transition: all 250ms;
}

.my-btn:hover {
  transform: scale(1.1);
}

.my-card {
  transition: all 150ms ease-in-out;
}

.my-card:hover {
  transform: scale(1.05);
}

.my-card-img {
  animation-name: example;
  animation-duration: 2s;
}

.my-drawer-item {
  width: 100%;
}

.my-drawer {
  width: 250px;
}

.drawer-div {
  height: 100%;
}

.drawer-icon {
  color: white;
}

.floating {
  position: fixed;
  margin: 0;
  right: 0;
  bottom: 0;
  width: 300px;
}
